import { Control, Required } from "@recursyve/ngx-form-generator";

export class LoginForm {
    @Control()
    @Required()
    username: string;

    @Control()
    @Required()
    password: string;
}
