import { CanActivate, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AppStore } from "../store/app.store";

@Injectable()
export class StreamGuard implements CanActivate {
    constructor(
        private store: AppStore,
        private router: Router
    ) {}

    public canActivate(): boolean | UrlTree {
        const { auth } = this.store.getValue();
        if (!auth) {
            return this.router.createUrlTree(["/login"]);
        }
        return true;
    }
}
