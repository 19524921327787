import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface StreamViewState {
    connection: RTCPeerConnection;
    stream: MediaStream;
    senders: RTCRtpSender[];
}

@Injectable()
@StoreConfig({ name: "stream" })
export class StreamViewStore extends Store<StreamViewState> {
    constructor() {
        super({});
    }
}
