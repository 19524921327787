import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { NgxFormGeneratorModule } from "@recursyve/ngx-form-generator";
import { NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit";

import { LoginComponent } from "./login.component";
import { LoginQuery } from "./store/login.query";
import { LoginStore } from "./store/login.store";
import { LoginService } from "./store/login.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        NgxFormGeneratorModule,
        NiceLoadingSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule
    ],
    declarations: [LoginComponent],
    exports: [LoginComponent],
    providers: [
        LoginService,
        LoginQuery,
        LoginStore
    ]
})
export class LoginModule {
}
