import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { MessagesModel } from "./models/messages.model";
import { UrlPublishModel } from "../models/auth-response.model";

@Injectable({ providedIn: "root" })
export class FlussonicApi {
    private ws: WebSocket;

    public open$ = new Subject();
    public message$ = new Subject<MessagesModel>();

    public openConnection(publishUrl: UrlPublishModel) {
        this.ws = new WebSocket(this.getWSUrl(publishUrl));
        this.ws.onopen = () => {
            this.open$.next();
        };
        this.ws.onmessage = (event) => {
            this.message$.next(JSON.parse(event.data));
        };
    }

    public closeConnection() {
        this.ws.close();
    }

    public sendLocalDescription(data: RTCSessionDescriptionInit) {
        this.ws.send(JSON.stringify(data));
    }

    public sendCandidate(candidate: RTCIceCandidate) {
        this.ws.send(JSON.stringify({
            candidate,
            type: "candidate",
            stream_id: "local1",
            label: candidate.sdpMLineIndex,
            id: candidate.sdpMid
        }));
    }

    private getWSUrl(publishUrl: UrlPublishModel): string {
        return publishUrl.http
            .replace("http", "wss")
            .replace(":80", "")
            .replace("mpegts", "webrtc/publish");
    }
}
