import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { OptionBottomSheetComponent } from "./option-bottom-sheet.component";

@NgModule({
    imports: [CommonModule, MatBottomSheetModule, MatListModule, MatIconModule],
    declarations: [OptionBottomSheetComponent],
    exports: [OptionBottomSheetComponent]
})
export class OptionBottomSheetModule {}
