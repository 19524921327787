import { NgModule } from "@angular/core";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { LoginModule } from "./features/login/login.module";
import { AppQuery } from "./store/app.query";
import { AppStore } from "./store/app.store";
import { AppService } from "./store/app.service";
import { StreamModule } from "./features/stream/stream.module";
import { LoginGuard } from "./guards/login.guard";
import { StreamGuard } from "./guards/stream.guard";

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatIconModule,

        environment.production ? [] : AkitaNgDevtools,
        AkitaNgRouterStoreModule,

        LoginModule,
        StreamModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AppQuery,
        AppService,
        AppStore,

        LoginGuard,
        StreamGuard,

        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
