<div fxFlex fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="center center" class="h-100-p">
        <div *ngIf="auth$ | async as auth" fxFlex="5" fxLayoutAlign="center center">
            <h4 >Votre code de diffusion: <b>{{ auth.auth_code }}</b></h4>
        </div>

        <div fxFlex="95" class="w-100-p">
            <button mat-button mat-icon-button class="option-button" (click)="clickOptions()">
                <mat-icon>more_vert</mat-icon>
            </button>
            <video #videoPlayer class="w-100-p h-100-p" controls autoplay style="background-color: #000"></video>
        </div>
    </div>
</div>

<div class="error-box red-400 white-fg" *ngIf="error$ | async as error">
    {{ error }}
</div>
