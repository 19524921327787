import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LoginStore } from "./login.store";
import { ApiService } from "../../../api/api.service";
import { AppService } from "../../../store/app.service";

@Injectable()
export class LoginService {
    constructor(
        private apiService: ApiService,
        private loginStore: LoginStore,
        private appService: AppService,
        private router: Router
    ) {}

    public async login(username: string, password: string) {
        this.loginStore.setLoading(true);
        try {
            const res = await this.apiService.authenticate(username, password).toPromise();
            if (res.success) {
                localStorage.setItem("username", username);
                this.appService.setAuthResponse(res);
                this.router.navigate(["/stream"]);
            } else {
                this.loginStore.setError(res.error);
            }
        } catch (e) {
            this.loginStore.setError("Une erreur est survenue.");
        } finally {
            this.loginStore.setLoading(false);
        }
    }

    public resetError() {
        this.loginStore.setError(false);
    }
}
