import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { StreamViewQuery } from "./store/stream.query";
import { StreamViewService } from "./store/stream.service";
import { AppQuery } from "../../store/app.query";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { OptionBottomSheetComponent, OptionBottomSheetConfig } from "./components/option-bottom-sheet/option-bottom-sheet.component";

@Component({
    selector: "elpc-stream",
    templateUrl: "stream.template.html",
    styleUrls: ["stream.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class StreamComponent implements OnInit {
    @ViewChild("videoPlayer")
    public player: ElementRef<HTMLVideoElement>;

    public auth$ = this.appQuery.select(x => x.auth);
    public error$ = this.query.selectError();
    public stream$ = this.query.select(x => x.stream);

    constructor(
        private readonly appQuery: AppQuery,
        private readonly query: StreamViewQuery,
        private readonly service: StreamViewService,
        private readonly bottomSheet: MatBottomSheet
    ) {}

    public ngOnInit() {
        this.auth$.subscribe((auth) => {
            if (!auth) {
                return;
            }

            this.service.openConnection(auth);
        });
        this.stream$.subscribe((stream) => {
            if (!stream) {
                return;
            }

            this.player.nativeElement.srcObject = stream;
            this.player.nativeElement.muted = true;
        });
    }

    public async clickOptions() {
        await this.bottomSheet.open<OptionBottomSheetComponent, OptionBottomSheetConfig>(OptionBottomSheetComponent, {
            data: {
                onClickSwitchCamera: () => this.service.askForMedia(true)
            }
        });
    }
}
