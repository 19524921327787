import { Injectable } from "@angular/core";
import { FlussonicApi } from "../../../api/flussonic/flussonic.api";
import { AuthResponseModel } from "../../../api/models/auth-response.model";
import { StreamViewStore } from "./stream.store";
import { MessagesModel } from "../../../api/flussonic/models/messages.model";

@Injectable()
export class StreamViewService {
    private mode = "environment";

    constructor(
        private readonly flussonicApi: FlussonicApi,
        private readonly store: StreamViewStore
    ) {
        this.flussonicApi.open$.subscribe(() => this.onConnection());
        this.flussonicApi.message$.subscribe((message) => this.onMessage(message));
    }

    public openConnection(auth: AuthResponseModel) {
        this.flussonicApi.openConnection(auth.url_publish);
    }

    public async setStream(newStream: MediaStream, switchStream: boolean = false): Promise<void> {
        const { connection, senders } = this.store.getValue();
        if (!connection) {
            return;
        }

        const tracks = newStream.getTracks();
        try {
            if (senders) {
                await senders.map((sender, i) => sender.replaceTrack(tracks[i]));
            } else {
                const s = tracks.map((track) => connection.addTrack(track, newStream));
                this.store.update({
                    senders: s
                });
            }
        } catch (e) {
            this.store.setError("Impossible de changer la caméra");
        }

        if (switchStream) {
            return;
        }
        const description = await connection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true
        });
        await connection.setLocalDescription(description);
        await this.flussonicApi.sendLocalDescription(description);
    }

    private onConnection(): void {
        const connection = new RTCPeerConnection();
        connection.onicecandidate = this.onIceCandidate.bind(this);
        this.store.update({
            connection
        });
        this.askForMedia();
    }

    private async onMessage(message: MessagesModel): Promise<void> {
        const { connection } = this.store.getValue();
        if (!connection) {
            return;
        }

        switch (message.type) {
            case "answer":
                await connection.setRemoteDescription(new RTCSessionDescription(message as RTCSessionDescriptionInit));
                break;
            case "candidate":
                await connection.addIceCandidate(new RTCIceCandidate(message.candidate));
                break;
        }
    }

    private onIceCandidate(event: RTCPeerConnectionIceEvent) {
        if (!event.candidate) {
            return;
        }
        this.flussonicApi.sendCandidate(event.candidate);
    }

    public async askForMedia(switchMode = false): Promise<void> {
        if (switchMode) {
            this.mode = this.mode === "environment" ? "user" : "environment";
        }

        try {
            const { stream }  = this.store.getValue();
            if (stream) {
                stream.getTracks().forEach(x => x.stop());
            }

            const s = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: {
                    facingMode: this.mode
                }
            });
            await this.setStream(s, switchMode);
            this.store.update({
                stream: s
            });
        } catch (e) {
            console.log(e);
            this.store.setError("Impossible d'avoir accès à la caméra");
        }
    }
}
