import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { StreamComponent } from "./stream.component";
import { StreamViewQuery } from "./store/stream.query";
import { StreamViewStore } from "./store/stream.store";
import { StreamViewService } from "./store/stream.service";
import { OptionBottomSheetModule } from "./components/option-bottom-sheet/option-bottom-sheet.module";

@NgModule({
    imports: [
        CommonModule,
        ExtendedModule,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        MatBottomSheetModule,
        OptionBottomSheetModule
    ],
    declarations: [StreamComponent],
    providers: [
        StreamViewQuery,
        StreamViewService,
        StreamViewStore
    ],
    exports: [StreamComponent],
})
export class StreamModule {
}
