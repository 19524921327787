import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

// tslint:disable-next-line:no-empty-interface
export interface LoginState {}

@Injectable()
@StoreConfig({ name: "login" })
export class LoginStore extends Store<LoginState> {
    constructor() {
        super({});
    }
}
