import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { StreamViewState, StreamViewStore } from "./stream.store";

@Injectable()
export class StreamViewQuery extends Query<StreamViewState> {
    constructor(store: StreamViewStore) {
        super(store);
    }
}
