import { Component, Inject } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";

export interface OptionBottomSheetConfig {
    onClickSwitchCamera: () => void;
}

@Component({
    selector: "elpc-option-bottom-sheet",
    templateUrl: "option-bottom-sheet.template.html"
})
export class OptionBottomSheetComponent {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public config: OptionBottomSheetConfig,
        private bottomSheetRef: MatBottomSheetRef<OptionBottomSheetComponent>
    ) {}

    public clickSwitchCamera() {
        this.bottomSheetRef.dismiss();
        this.config.onClickSwitchCamera();
    }
}
