import { Store, StoreConfig } from "@datorama/akita";
import { AuthResponseModel } from "../api/models/auth-response.model";

export interface AppState {
    auth: AuthResponseModel;
}

export function createInitialState(): AppState {
    return {
        auth: null
    };
}

@StoreConfig({ name: "app" })
export class AppStore extends Store<AppState> {
    constructor() {
        super(createInitialState());
    }
}
