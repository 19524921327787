import { Component, HostListener, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { niceAnimations } from "@recursyve/nice-ui-kit";
import { LoginForm } from "./login.form";
import { LoginService } from "./store/login.service";
import { LoginQuery } from "./store/login.query";

@Component({
    selector: "elpc-login",
    templateUrl: "login.template.html",
    styleUrls: ["./login.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: niceAnimations,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(LoginForm)
        }
    ]
})
export class LoginComponent implements OnInit {
    public year = new Date().getFullYear();

    public loading$ = this.loginQuery.selectLoading();
    public error$ = this.loginQuery.selectError();

    constructor(
        public formGroup: GeneratedFormGroup<LoginForm>,
        private loginService: LoginService,
        private loginQuery: LoginQuery,
        private route: ActivatedRoute
    ) {}

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event) {
            this.loginService.resetError();
            if (event.keyCode === 13) {
                this.login();
            }
        }
    }

    public ngOnInit() {
        this.route.queryParamMap.subscribe((param) => {
            const form: LoginForm = {} as LoginForm;
            if (param.has("user")) {
                form.username = param.get("user");
            }
            if (param.has("pass")) {
                form.password = param.get("pass");
            }
            this.formGroup.patchValue(form);
        });

        const username = localStorage.getItem("username");
        if (username) {
            this.formGroup.patchValue({
                username
            } as LoginForm);
        }
    }

    public login() {
        if (!this.validate()) {
            return;
        }
        const { username, password } = this.formGroup.getRawValue();
        this.loginService.login(username, password);
    }

    public validate(): boolean {
        if (this.formGroup.valid) {
            return true;
        }
        this.formGroup.markAllAsTouched();
        return false;
    }
}
