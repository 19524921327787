<div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div
                id="login-form"
                [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
                [niceLoadingOverlay]="loading$ | async"
        >
            <div fxLayout="column" fxLayoutAlign="center center" class="mb-16">
                <div class="logo">
                    <img src="../../../assets/images/logo.png"/>
                </div>
            </div>

            <form name="loginForm" [formGroup]="formGroup" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "Nom d'utilisateur" }}</mat-label>
                    <input matInput formControlName="username" (keydown)="onChange($event)"/>
                    <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                    <mat-error *ngIf="error$ | async"></mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ "Mot de passe" }}</mat-label>
                    <input matInput type="password" formControlName="password" (keydown)="onChange($event)"/>
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="error$ | async"></mat-error>
                </mat-form-field>
                <mat-error class="mb-8" *ngIf="error$ | async as error">
                    {{ error }}
                </mat-error>

                <button mat-raised-button color="accent" type="button" class="submit-button text-uppercase" aria-label="LOG IN" (click)="login()">
                    {{ "Se connecter" }}
                </button>
            </form>

            <p class="login-footer">
                <small>ELPC &copy; {{ year }}</small>
            </p>
        </div>
    </div>
</div>
