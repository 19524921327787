import { Injectable } from "@angular/core";
import { AppStore } from "./app.store";
import { AuthResponseModel } from "../api/models/auth-response.model";

@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(private appStore: AppStore) {}

    public setAuthResponse(auth: AuthResponseModel) {
        this.appStore.update({
            auth
        });
    }
}
