import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./features/login/login.component";
import { StreamComponent } from "./features/stream/stream.component";
import { StreamGuard } from "./guards/stream.guard";
import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
    {
        path: "", redirectTo: "stream", pathMatch: "full"
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: "stream",
        component: StreamComponent,
        canActivate: [StreamGuard]
    },
    {
        path: "**", redirectTo: "stream", pathMatch: "full"
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
