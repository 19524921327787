import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthResponseModel } from "./models/auth-response.model";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    private readonly apiUrl = environment.apiUrl;

    constructor(private readonly http: HttpClient) {}

    public authenticate(username: string, password: string): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>(this.apiUrl, {
            user: username,
            pass: password
        });
    }
}
